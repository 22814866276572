<template>
  <div>
    <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
      <div class="container">
        <div class="navbar-brand">
          <router-link class="navbar-item is-size-4 has-text-weight-bold" :to="'/'">
            Simple Manager
          </router-link>

          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
            :class="{'is-active': menuActive}" @click="menuActive = !menuActive">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu"  :class="{'is-active': menuActive}">
          <div class="navbar-start" v-if="token">
            <router-link class="navbar-item" :to="'/invoices'" v-if="groups && groups.includes('InvoiceUsers')">
              Invoices
            </router-link>
            <router-link class="navbar-item" :to="'/products'" v-if="groups && groups.includes('PriceMonitorUsers')">
              Price Monitor
            </router-link>
            <router-link class="navbar-item" :to="'/products-sales'" v-if="groups && groups.includes('SalesUsers')">
              Sales
            </router-link>
            <router-link class="navbar-item" :to="'/products-landed-costs'" v-if="groups && groups.includes('SalesUsers')">
              Landed Costs
            </router-link>
            <div class="navbar-item has-dropdown is-hoverable" v-if="groups && groups.includes('SalesUsers')">
              <router-link class="navbar-link" :to="'/forecast'">
                Forecast
              </router-link>
              <div class="navbar-dropdown">
                <router-link class="navbar-item" :to="'/forecast'">
                  US
                </router-link>
                <router-link class="navbar-item is-selected" :to="'/forecast-canada'">
                  Canada
                </router-link>
              </div>
            </div>
            <router-link class="navbar-item" :to="'/returned-items'" v-if="groups && groups.includes('ReturnsUsers')">
              Returns
            </router-link>
          </div>

          <div class="navbar-end">
            <a class="navbar-item" v-if="staff" target="_blank" :href="adminUrl">
              <span class="icon">
                <i class="fas fa-database"></i>
              </span>
            </a>
            <router-link class="navbar-item" v-if="superuser" :to="'/my-config'">
              <span class="icon">
                <i class="fas fa-cog"></i>
              </span>
            </router-link>
            <router-link class="navbar-item" v-if="!token" :to="'/'">
              <span class="icon">
                <i class="fas fa-sign-in-alt"></i>
              </span>
              <span>Sign in</span>
            </router-link>
            <a class="navbar-item" v-if="token" @click="signout">
              <span class="icon">
                <i class="fas fa-sign-out-alt"></i>
              </span>
              <span>Sign out</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'Header',
  data () {
    return {
      menuActive: false
    }
  },
  computed: {
    token () {
      return this.$store.state.user.token
    },
    staff () {
      return this.$store.state.user.staff
    },
    superuser () {
      return this.$store.state.user.superuser
    },
    groups () {
      return this.$store.state.user.groups
    },
    routerPath () {
      return this.$route.path
    },
    server () {
      return this.$store.state.config.server
    },
    adminUrl () {
      return this.server + '/admin'
    },
  },
  methods: {
    signout () {
      var confirm = {
        title: 'Sign Out',
        message: 'Are you sure to sign out?',
        button: 'Yes, I am sure.',
        callback: {
          context: this,
          method: this.signoutConfirmed,
          args: []
        }
      }
      this.$store.commit('modals/openConfirmModal', confirm)
    },
    signoutConfirmed () {
      delete Vue.http.headers.common['Authorization']
      this.$store.commit('user/reset')
      if (this.routerPath != '/') {
        this.$router.push('/')
      }
    },
    getVendorSubsidiary () {
      this.$http.get(this.server + '/myapp/get-vendor-subsidiaries/').then(resp => {
        this.$store.commit('config/setVendorSubsidiary', resp.body)
      })
    },
  },
  mounted () {
    if (this.token) {
      Vue.http.headers.common['Authorization'] = 'Token ' + this.token
      if (this.groups && this.groups.includes('InvoiceUsers')) {
        this.getVendorSubsidiary()
      }
    }
  }
}
</script>